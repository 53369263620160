import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import styled from 'styled-components'
import Cale from "../../content/assets/cale1.jpg"

class Projects extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const AboutWrapper = styled.div`
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      /*width: 80%;
      height: 80%;*/
    `;

    const AboutItems = styled.div`
      width: 100%;
      height: 100%;
      /*padding: 1em;*/
      margin: .5em;
      font-family: Montserrat, sans-serif;
    `;

    return (
      <Layout location={`/projects`} title={siteTitle}>
        <SEO title="Projects" />
        <AboutWrapper>
          <AboutItems>
            <h1>Fly Ball - Ahem</h1>
            <p>After releasing a <a target="_blank" href="https://slaveshiprebellion.bandcamp.com/">bunch</a> <a href="https://soundcloud.com/kL_sH" target="_blank">of</a> <a href="https://www.reverbnation.com/imkl" target="_blank">bedroom</a> <a href="https://thekingofpop.bandcamp.com/" target="_blank">and</a> <a href="https://soundcloud.com/pancakewhat" target="_blank">jam</a> <a href="https://soundcloud.com/fly-ball" target="_blank">room</a> <a href="https://www.youtube.com/watch?v=Tdl-gSC_9hg" target="_blank">recordings</a> over the years, I wanted to 
              finally release music which had all the fixins (professional recording, mixing, and mastering).
              A good 18 months after recording our album at <a href="https://www.thenoisefloor.ca/" target="_blank">the Noise Floor</a>, we finally birthed it in the summer
              of 2019.
            </p>
            <p>It sounds great! Buy a hand-crafted tape!</p>
            <iframe style={{border: "0", width: "100%", height: "120px"}} src="https://bandcamp.com/EmbeddedPlayer/album=2725786074/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/" seamless><a href="http://flyball.bandcamp.com/album/ahem">Ahem by Fly Ball</a></iframe>
          </AboutItems>
          <AboutItems>
            <h1>Jess Sung Portfolio Website</h1>
            <p>In my first non-squarespace foray into web development, I made <a target="_blank" href="https://jesssung.com/">a portfolio website</a> for my
              friend, Jess Sung, a photographer.
            </p>
            <p>
              I wanted to see if I could create a modern, responsive website from scratch without any tools
              or libraries. Jess gave me a few examples of sites to "take inspiration from", and I went to work
            </p>
            <p>I learned many, many lessons during this project (like why using JS to dynamically generate menus
              is worthwhile... after many, many menu updates). In the end I was happy with the project, and
            my friend got an extremely cheap website that she had to learn HTML and git in order to update ;)</p>
            <a target="_blank" href="https://jesssung.com/"><img src="https://jesssung.com/frontpage/0017_15A1.jpg"></img></a>
          </AboutItems>
          <AboutItems>
            <h1>Clio Hackathon Forum</h1>
            <p>While teaching myself to program, I was fortunate enough to work for a company
              which held quarterly hackathons. For the third hackathon, I led a team trying to build a meta-project:
             a web app which could be used to plan and organize hackathon projects!</p>
             <p>
               It was a hectic mess figuring out databases, authentication, SPA routing, etc, and I can't
               count all of the google rabbit-holes I went down, but in the end I got a functioning app!
             </p>
             <p>Anyone want to have a hackathon?</p>
             <a href="https://cshape.github.io/hackathon-forum/#/" target="_blank"><img src="https://cshape.github.io/dev-portfolio/assets/images/projects/hackathon.png"></img></a>
          </AboutItems>
        </AboutWrapper>
        
      </Layout>
    )
  }
}

export default Projects

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
